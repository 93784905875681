<template>
  <div class="icon-list">
    <NuxtLink
      class="icon-item"
      target="_blank"
      :to="`${config.public.VITE_USER_CENTER}/user-home`"
      v-if="getUserType(data.basicInfo.identityInfoType) === '普通用户' || !getUserType(data.basicInfo.identityInfoType)"
    >
      <img src="@/assets/icons/pc_home_settlein.svg" />
      <span>入驻平台</span>
    </NuxtLink>
    <NuxtLink
      class="icon-item"
      target="_blank"
      :to="config.public.VITE_MALL_ADMIN"
      v-if="getUserType(data.basicInfo.identityInfoType) === '品牌商' || getUserType(data.basicInfo.identityInfoType) === '经销商'"
    >
      <img src="@/assets/icons/pc_home_backstage.svg" />
      <span>商家后台</span>
    </NuxtLink>
    <a-trigger :popup-translate="[0, 30]" :unmount-on-close="false">
      <div class="icon-item relative" v-if="hasCookie()">
        <a-badge class="badge" :count="9" dot></a-badge>
        <img src="@/assets/icons/pc_home_msg_icon.svg" />
        <span>消息</span>
      </div>
      <template #content>
        <QuestionMsgCard />
      </template>
    </a-trigger>
  </div>
</template>

<script setup lang="ts">
import { MagicHouseAppUserDto } from "@/apis/api";
import { useMessage } from "naive-ui";
import userStore from "@/stores/user";
const message = useMessage();
const useUser = userStore();
const config = useRuntimeConfig();

interface dataType {
  basicInfo: MagicHouseAppUserDto;
}
const data = reactive<dataType>({
  basicInfo: {}, //用户信息
});
// 跳转
function toUrl(code: number) {
  switch (code) {
    //入驻平台
    case 1:
      window.open(`${config.public.VITE_USER_CENTER}/user-home`);
      break;
    //商家后台
    case 2:
      window.open(config.public.VITE_MALL_ADMIN);
      break;
    //消息
    default:
      message.loading("跳转消息页");
      break;
  }
}

onMounted(() => {
  data.basicInfo = computed(() => useUser.basicInfo) as MagicHouseAppUserDto;
  // console.log('user',data.basicInfo);
});
</script>

<style lang="less" scoped>
// 链接
.icon-list {
  @apply flex;
  height: 100%;

  // margin-left: 32px;
  .icon-item {
    @apply flex flex-col justify-between items-center text-f12 cursor-pointer relative;
    min-width: 10px;
    height: 100%;
    margin-left: 23px;

    img {
      width: 18px;
      height: 18px;
    }
    :deep(.badge) {
      position: absolute;
      right: 0;
      top: -6px;
    }
  }
}
</style>
